import React from 'react';

import './app.scss';

class App extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col s12 m5">
                    <div className="card">
                        <div className="card-content">
                            <span className="card-title">Veera Raamatupidamisteenused OÜ</span>
                            <p className="info">
                                Kasutusmugavuse parandamiseks ning info paremaks leidmiseks läbib veebilehekülg hetkel uuenduskuuri. Selleks, et töö seisma ei jääks, leiate alt meie kontaktid.
                            </p>
                            <div className="contacts">
                                <ul className="contact-listing text-center">
                                    <li>
                                        <span className="app-icon">
                                            <i className="material-icons">phone</i>
                                            +3727762629
                                        </span>
                                    </li>
                                    <li>
                                        <span className="app-icon">
                                            <i className="material-icons">phone</i>
                                            +53437420
                                        </span>
                                    </li>
                                    <li>
                                        <span className="app-icon">
                                            <i className="material-icons">email</i>
                                            veera.raamatupidamine@gmail.com
                                        </span>
                                    </li>
                                    <li>
                                        <span className="app-icon">
                                            <i className="material-icons">home</i>
                                            Suur tn. 14, Jõgeva , 48306
                                        </span>
                                    </li>
                                    <li>
                                        <span className="app-icon">
                                            <i className="material-icons">home</i>
                                            Aia 26, Mustvee , 49604
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <p>
                                Raamatupidamine väikeettevõttele on meie erialaks olnud juba üle 14 aasta. Aitame ettevõtjaid raamatupidamise ja ettevõtluse valdkonnas, spetsialiseerudes alustavate ettevõtetele raamatupidamisele ning finants- ja maksuprobleemide lahendamisele.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;